import React from 'react';
import { createRoot } from 'react-dom/client';
import Videojs from './video.js';


const urlParams = new URLSearchParams(window.location.search);
const sourceUrl = urlParams.get('url');
const title = urlParams.get('title');
document.title=title;

console.log(sourceUrl);
console.log(title);

const videoJsOptions = {
  autoplay: true, 

  //liveui: true,   
  //width: window.innerWidth / 2,
  //height: window.innerHeight / 2,
  aspectRatio: "16:9",
  controls: true,
  //fluent: true,
  preload: 'auto',
  techOrder: [ 'chromecast', 'html5' ],
  sources: [
    {
      src: sourceUrl,
      type: 'video/mp4',
    },
  ],
};

const App = () =>
  <div>
    <Videojs {...videoJsOptions} />
  </div>;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);